<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">File to upload </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col md="12" cols="12">
              <v-file-input
                small-chips
                counter
                placeholder="Select your files"
                multiple
                clearable
                label="Add files"
                @change="previewImage"
                outlined
                v-model="image_vaults"
              >
                <template v-slot:selection="{ index, text, file }">
                  <v-chip
                    small
                    text-color="white"
                    color="primary"
                    close
                    @click:close="removeFileUpload(index, file.image_id)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-row>
                <v-col sm="3" md="3" v-for="(file, index) in image_vaults" :key="index">
                  <img :ref="'file'" class="img-fluid" :src="file.path" width="130" height="130" />
                  {{ file.name }}
                </v-col>
              </v-row>
              <input type="hidden" name="inspection_item_id" v-model="inspection_item_id" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="submitUpload">
            <v-icon right dark>mdi-cloud-upload</v-icon>
            <span>Upload</span>
          </v-btn>

          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mdiPlus, mdiInformation, mdiContentSave, mdiDeleteForeverOutline, mdiClose, mdiCash } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    dialog: Boolean,
    inspection_item_id: Number,
  },

  data() {
    return {
      image_vaults: [],
      readers: [],

      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
      },
    }
  },
  methods: {
    getInspectionUploads: function() {
      let itemid = this.inspection_item_id
      this.$store
        .dispatch('imageVault/fetchImageVaults', {
          inspection_item_id: itemid,
        })
        .then(response => {
          this.image_vaults = response
          this.image_vaults = this.fetchImages
        })
        .catch(err => {
          console.log(err)
        })
    },

    previewImage: function() {
      this.image_vaults.forEach((file, f) => {
        this.readers[f] = new FileReader()
        this.readers[f].onload = e => {
          let fileData = this.readers[f].result
          let imgRef = this.$refs.file[f]
          imgRef.src = fileData
          //console.log(fileData)
          // send to server here...
        }
        this.readers[f].readAsDataURL(this.image_vaults[f])
      })
    },

    submitUpload: function() {
      let currentObj = this
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      let formData = new FormData()

      //append files
      for (let i = 0; i < this.image_vaults.length; i++) {
        formData.append('image_vaults[]', this.image_vaults[i])
        formData.append('inspection_item_id', this.inspection_item_id)
      }

      //send upload request
      this.$store
        .dispatch(`inspection/storeNewImageVault`, formData, config)
        .then(function(response) {
          this.close()
          currentObj.success = response.data.success
          currentObj.filename = ''
        })
        .catch(function(error) {
          currentObj.output = error
        })
    },

    removeFileUpload: function(index, id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`imageVault/deleteImageVault`, { image_id: id })
              .then(response => {
                this.image_vaults.splice(index, 1)
                this.getInspectionUploads()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    close() {
      this.$emit('close-dialog')
    },
  },

  watch: {
    inspection_item_id: function() {
      this.getInspectionUploads()
    },
  },

  computed: {
    ...mapGetters({
      fetchImages: 'imageVault/fetchImageVaults',
    }),
  },
}
</script>

<style></style>
