<template>
  <v-card elevation="1">
    <v-tabs color="primary">
      <v-tab>
        <v-icon left></v-icon>
        Customer Concern
      </v-tab>
      <v-tab>
        <v-icon left></v-icon>
        Technician Concern
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <CustomerConcern :estimate_id="estimate_id" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TechnicianConcern :estimate_id="estimate_id" />
          <v-divider></v-divider>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-divider class="mb-5 mt-3"></v-divider>
    <v-row>
      <div v-if="addNewTemplate" class="row ma-5">
        <v-card
          v-for="(inspection_template, index) in inspection_templates"
          :key="index"
          flat
          color="transparent"
          class="ma-4"
        >
          <v-avatar color="secondary" size="70">
            <v-icon size="40" color="gray">
              {{ icons.mdiClipboardListOutline }}
            </v-icon>
          </v-avatar>
          <v-card-subtitle class="pb-0 mt-5" style="font-weight: bold">
            <input type="text" name="estimate_id" v-model="inspectiontemplate.estimate_id" />
            {{ inspection_template.name }}
          </v-card-subtitle>
          <v-card-subtitle> Items({{ inspection_template.items }}) </v-card-subtitle>
          <v-card-actions>
            <v-btn
              class="mt-n5"
              color="primary"
              plain
              @click.prevent="addOrCreateInspection(inspection_template.inspection_id)"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon
              >Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else-if="inspections.length" class="row my-3">
        <v-expansion-panels multiple focusable v-model="panel" class="mx-5">
          <v-expansion-panel v-for="(inspection, index1) in inspections" :key="index1">
            <v-expansion-panel-header @keyup.space.prevent>
              <template v-slot:actions>
                <v-icon color="primary" class="icon">{{ icons.mdiMenuDown }}</v-icon>
              </template>
              <v-layout class="header">
                <strong class="mx-2 mt-2">{{ inspection.name }} </strong>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.stop @click.prevent="saveInspection" class="mx-1" fab x-small>
                  <v-icon size="20">{{ icons.mdiContentSave }}</v-icon>
                </v-btn>
                <v-btn color="info" @click.native.stop="" class="mx-1" fab x-small>
                  <v-icon size="20">{{ icons.mdiCheckCircleOutline }}</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  @click.stop
                  @click.prevent="removeInspection(inspection.inspection_id)"
                  class="mx-1"
                  fab
                  x-small
                >
                  <v-icon size="20">{{ icons.mdiDeleteForeverOutline }}</v-icon>
                </v-btn>
              </v-layout>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row>
                <v-col cols="12" md="3" sm="3">
                  <span>Rating</span>
                </v-col>
                <v-col cols="12" md="4" sm="4">
                  <span>Inspection Task</span>
                </v-col>
                <v-col cols="12" md="5" sm="5">
                  <span>Findings</span>
                </v-col>
              </v-layout>

              <v-expansion-panels multiple focusable v-model="panel" class="ml-n4">
                <v-expansion-panel
                  v-for="(category_item, index2) in inspection.inspection_category_items"
                  :key="index2"
                >
                  <v-expansion-panel-header @keyup.space.prevent>
                    <template v-slot:actions>
                      <v-icon color="primary" class="icon">{{ icons.mdiMenuDown }}</v-icon>
                    </template>
                    <v-layout
                      v-for="(category_group, index3) in category_item.inspection_category"
                      :key="index3"
                      class="header"
                    >
                      <strong class="mx-2">{{ category_group.name }} </strong>
                    </v-layout>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form class="multi-col-validation">
                      <v-layout v-for="(item, index4) in category_item.inspection_items" :key="index4" row>
                        <v-col cols="12" md="3" sm="3">
                          <v-radio-group v-model="category_item.inspection_items[index4].rating" class="mt-n1" row>
                            <v-radio color="success" value="Check & Okay"></v-radio>
                            <v-radio color="info" value="May need attention"></v-radio>
                            <v-radio color="error" value="Needs immediate attention"></v-radio>
                            <v-radio color="secondary" value="Not applicable"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" md="4" sm="4">{{
                          category_item.inspection_items[index4].inspection_task
                        }}</v-col>
                        <v-col cols="12" md="4" sm="4">
                          <v-textarea
                            outlined
                            dense
                            v-model="category_item.inspection_items[index4].findings"
                            label="Findings"
                            color="secondary"
                            rows="2"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="1" sm="1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                @click.stop
                                @click.prevent="uploadFile(index1, index2, index4)"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2 mt-3"
                                fab
                                x-small
                              >
                                <v-icon size="25">mdi-cloud-upload</v-icon>
                              </v-btn>
                            </template>
                            <span>Upload File</span>
                          </v-tooltip>
                        </v-col>
                      </v-layout>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div :class="[inspections.length != 0 ? 'd-none' : 'block']" class="row mt-2 mb-2">
        <v-card
          v-for="(inspection_template, index) in inspection_templates"
          :key="index"
          flat
          color="transparent"
          class="ma-4 text-center"
        >
          <v-avatar color="secondary" size="70">
            <v-icon size="40" color="gray">
              {{ icons.mdiClipboardListOutline }}
            </v-icon>
          </v-avatar>
          <v-card-subtitle class="pb-0 mt-5" style="font-weight: bold">
            {{ inspection_template.name }}
          </v-card-subtitle>
          <v-card-subtitle> Items({{ inspection_template.items }}) </v-card-subtitle>
          <v-card-actions>
            <v-btn
              class="mt-n5"
              color="primary"
              plain
              @click.prevent="addOrCreateInspection(inspection_template.inspection_id)"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon
              >Add
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="ml-10">
          <v-btn
            @click.prevent="$router.push({ name: 'addnewinspectiontemplate' })"
            class="my-5"
            rounded
            color="primary"
          >
            <v-icon left> {{ icons.mdiPlus }}</v-icon>
            New
          </v-btn>
        </div>
      </div>
    </v-row>
    <upload
      :dialog.sync="showUploadfile"
      :inspection_item_id="inspection_item_id"
      @open-dialog="showUploadfile = true"
      @close-dialog="showUploadfile = false"
    ></upload>
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import {
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiDeleteForeverOutline,
  mdiClose,
  mdiCash,
  mdiAccountTie,
  mdiClipboardCheckOutline,
  mdiClipboardListOutline,
  mdiMenuDown,
  mdiCheckCircleOutline,
} from '@mdi/js'
import CustomerConcern from '@/components/workflow/customerconcerns/CustomerConcern.vue'
import TechnicianConcern from '@/components/workflow/technicianconcerns/TechnicianConcern.vue'
import Upload from '@/components/workflow/inspections/UploadFile.vue'

export default {
  props: {
    estimate_id: Number,
  },
  components: {
    CustomerConcern,
    TechnicianConcern,
    Upload,
  },

  created: function() {
    this.getInspections()
    this.getInspectionTemplates()
  },

  data() {
    return {
      panel: [0, 1],
      inspections: [
        {
          inspection_id: '',
          estimate_id: '',
          name: '',
          inspection_category_items: [
            {
              inspection_grp_id: '',
              inspection_id: '',
              inspection_cat_id: '',
              inspection_category: [
                {
                  inspection_cat_id: '',
                  name: '',
                },
              ],
              inspection_items: [
                {
                  inspection_item_id: '',
                  inspection_grp_id: '',
                  rating: '',
                  inspection_task: '',
                  findings: '',
                  image_vaults: [],
                },
              ],
            },
          ],
        },
      ],

      inspection_templates: [],
      inspection_item_id: null,
      inspectiontemplate: {
        estimate_id: '',
      },
      addNewTemplate: false,
      showUploadfile: false,

      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
        mdiAccountTie,
        mdiClipboardCheckOutline,
        mdiClipboardListOutline,
        mdiMenuDown,
        mdiCheckCircleOutline,
      },
    }
  },

  methods: {
    getInspections: function() {
      let value = localStorage.storedData
      this.$store
        .dispatch('inspection/getInspections', {
          estimate_id: this.$route.params.id,
        })
        .then(response => {
          this.inspections = response.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    getInspectionTemplates: function() {
      this.$store
        .dispatch('inspection/fetchInspections')
        .then(response => {
          this.inspection_templates = this.fetchInspectionTemplates
          this.inspectiontemplate.estimate_id = this.estimateid
        })
        .catch(err => {
          console.log(err)
        })
    },
    uploadFile: function(index1, index2, index3) {
      this.showUploadfile = true
      this.inspection_item_id = this.inspections[index1].inspection_category_items[index2].inspection_items[
        index3
      ].inspection_item_id
      console.log(this.inspection_item_id)
    },

    addOrCreateInspection: function(inspection_id) {
      this.$store
        .dispatch('inspection/addOrCreateInspection', {
          inspection_id: inspection_id,
          estimate_id: this.inspectiontemplate.estimate_id,
        })
        .then(response => {
          localStorage.storedData = response.data.estimateid
          this.getInspections()
        })
        .catch(err => {
          console.log(err)
        })
    },

    saveInspection: debounce(function() {
      this.$store
        .dispatch(`inspection/storeNewInspection`, {
          inspections: this.inspections,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
          Toast.fire({
            icon: 'success',
            title: 'Record saved successfully!',
          })
          this.getInspections()
        })
        .catch(err => {
          console.log(err)
        })
    }, 800),

    removeInspection: function(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inspection/deleteInspection`, {
                inspection_id: id,
              })
              .then(response => {
                this.getInspections()
              })
          }
        })
    },
  },
  computed: {
    ...mapGetters({
      fetchInspectionCategories: 'inspection/fetchInspectionCategories',
      fetchInspectionTemplates: 'inspection/fetchInspections',
    }),
    estimateid() {
      return this.$route.params.id
    },
  },
}
</script>

<style>
.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
