<template>
  <v-card elevation="1">
    <v-container>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-layout>
            <v-btn @click.prevent="showSearchCannedDialog" color="primary" plain>
              <v-icon left> {{ icons.mdiMagnify }}</v-icon>
              <span> Search or browse canned services </span>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
              <v-layout>
                <v-btn @click.prevent="addnewService" color="primary" plain>
                  <v-icon left>{{ icons.mdiPlus }}</v-icon>
                  <span>New Service</span>
                </v-btn>
              </v-layout>
            </div>
          </v-layout>
        </v-col>
        <v-expansion-panels class="mb-3" inset>
          <v-expansion-panel v-for="(service, index1) in services" :key="index1">
            <v-expansion-panel-header @keyup.space.prevent>
              <template v-slot:actions>
                <v-icon color="primary" class="icon mt-n5">{{ icons.mdiMenuDown }}</v-icon>
              </template>
              <v-layout class="header" row>
                <v-col cols="12" md="8" sm="8">
                  <input type="hidden" v-model="services[index1].service_id" />
                  <input type="hidden" v-model="services[index1].estimate_id" />
                  <v-text-field
                    v-model="services[index1].name"
                    @click.stop
                    @click.prevent=""
                    color="secondary"
                    label="Enter name of this service"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
               
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      @click.stop
                      @click.prevent=""
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1 mt-4"
                      fab
                      x-small
                    >
                      <v-icon v-if="!authorized" size="20">{{ icons.mdiInformationOutline }}</v-icon>
                      <v-icon v-else size="20">{{ icons.mdiCheckCircleOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Not Authorized</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      @click.native.stop=""
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1 mt-4"
                      fab
                      x-small
                    >
                      <v-icon size="20">{{ icons.mdiAlphaRCircleOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Recommend</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      @click.stop
                      @click.prevent=""
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1 mt-4"
                      fab
                      x-small
                    >
                      <v-icon size="20">{{ icons.mdiEyeCircleOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Hidden</span>
                </v-tooltip>
                <div style="margin-top: 14px">
                  <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn light icon v-bind="attrs" v-on="on">
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="primary" class="mx-1">{{ icons.mdiContentSaveCog }}</v-icon>
                          <v-list-item-title>Save as Canned Service</v-list-item-title>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item @click.prevent="removeService(service.service_id)">
                        <v-list-item-icon>
                          <v-icon color="error" class="mx-1">{{ icons.mdiClose }}</v-icon>
                          <v-list-item-title>Remove</v-list-item-title>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-layout>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="services[index1].notes"
                    color="secondary"
                    dense
                    label="Notes (Optional)"
                    outlined
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card elevation="1">
                <v-toolbar height="45" flat color="primary" light>
                  <v-toolbar-title class="white--text">Service Items</v-toolbar-title>
                </v-toolbar>
                <v-tabs v-model="current_tab" horizontal>
                  <v-tab>
                    <v-icon left>mdi-archive-search-outline</v-icon>
                    Items
                  </v-tab>
                  <v-tab href="#tab-2">
                    <v-icon left> mdi-archive-arrow-up </v-icon>
                    Inventory Items
                  </v-tab>
                  <v-tab>
                    <v-icon left> mdi-information-variant </v-icon>
                    Additonal Info
                  </v-tab>
                  <v-tab-item>
                    <v-card flat>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-form class="multi-col-validation" id="service-item">
                          <v-row class="nowrap-overflow" v-for="(item, index2) in service.items" :key="index2">
                            <v-col cols="12" md="4" sm="4">
                              <input type="hidden" v-model="item.item_id" />
                              <div class="text-center">ITEM TYPE</div>
                              <v-select
                                v-model="item.item_type"
                                :items="itemtype"
                                deletable-chips
                                small-chips
                                item-value="item_type"
                                item-text="item_type"
                                color="secondary"
                                dense
                                outlined
                                single-line
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                              <div class="text-center">ITEM NAME</div>
                              <v-combobox
                                v-model="item.name"
                                :items="inventoryItems"
                                item-value="name"
                                item-text="name"
                                color="secondary"
                                dense
                                outlined
                                chips
                                deletable-chips
                                small-chips
                                label="Item Name"
                                single-line
                                :return-object="false"
                              ></v-combobox>
                            </v-col>
                            <v-col cols="12" md="2" sm="2">
                              <div class="text-center">PRICE</div>
                              <v-text-field
                                v-model="item.retail_price"
                                color="secondary"
                                label="Price"
                                prefix="$"
                                dense
                                outlined
                                @input="computeSubTotal(index1, index2)"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" sm="2">
                              <div class="text-center">QUANTITY</div>
                              <v-text-field
                                @input="computeSubTotal(index1, index2)"
                                v-model="item.qty_hrs"
                                color="secondary"
                                label="Quantity"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" sm="2">
                              <div class="text-center">DISCOUNT</div>
                              <v-text-field
                                @input="computeSubTotal(index1, index2)"
                                v-model="item.discount"
                                color="secondary"
                                label="Discount"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                              <div class="text-center">STATUS</div>
                              <v-combobox
                                v-model="item.status"
                                :items="itemstatus"
                                item-value="status"
                                item-text="status"
                                color="secondary"
                                dense
                                outlined
                                chips
                                deletable-chips
                                small-chips
                                label="Status"
                                single-line
                                :return-object="false"
                              ></v-combobox>
                            </v-col>
                            <v-col cols="12" md="3" sm="3">
                              <div class="text-center">SUBTOTAL</div>
                              <v-text-field
                                v-model="item.subtotal"
                                color="secondary"
                                label="SubTotal"
                                prefix="$"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2" sm="2">
                              <div class="text-center">ACTIONS</div>
                              <div class="d-flex justify-center">
                                <v-btn
                                  @click.prevent="
                                    nextTab('tab-2', item.item_id, item.item_type, index1, index2, $event)
                                  "
                                  small
                                  icon
                                  color="primary"
                                >
                                  <v-icon>{{ icons.mdiEyeCircleOutline }}</v-icon>
                                </v-btn>
                                <v-btn @click.prevent="deleteItem(item.item_id)" small icon color="error">
                                  <v-icon>{{ icons.mdiClose }}</v-icon>
                                  
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                          <div class="ml-n2 mt-4">
                            <v-btn @click.prevent="addnewItem(service.items, index1)" plain color="primary">
                              <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                              Add Item
                            </v-btn>
                          </div>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="tab-2" id="tab-2">
                    <v-card flat>
                      <v-card-text v-if="inventory.item_type == 'Part'">
                        <v-toolbar flat>
                          <v-toolbar-title class="page-title">
                            <v-icon class="secondary--text" left light>{{ icons.mdiTools }}</v-icon>
                            Part Inventory
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-btn color="accent" rounded @click.prevent="saveInventoryItems">
                            <v-icon left dark>{{ icons.mdiContentSave }}</v-icon>
                            <span>Save to Inventory</span>
                          </v-btn>
                        </v-toolbar>
                        <v-divider class="mb-2"></v-divider>
                        <v-form class="multi-col-validation">
                          <v-card max-height="350" align="left" class="overflow-y-auto">
                            <v-col md="6" cols="12">
                              <div class="mb-1">Name</div>
                              <input type="hidden" readonly v-model="inventory.item_id" />
                              <input type="hidden" readonly v-model="inventory.item_type" />
                              <input type="hidden" readonly v-model="inventory.key_id" />
                              <v-text-field
                                color="secondary"
                                v-model="inventory.name"
                                label="Name"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Description</div>
                              <v-text-field
                                color="secondary"
                                v-model="inventory.description"
                                label="Description"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Part Number</div>
                              <v-text-field
                                v-model="inventory.part_number"
                                color="secondary"
                                label="Part Number"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Bin Location</div>
                              <v-text-field
                                v-model="inventory.bin_location"
                                color="secondary"
                                label="Bin Location"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout>
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Inventory Category</div>
                                  <v-autocomplete
                                    v-model="inventory.inv_category_id"
                                    :items="inventoryCategories"
                                    item-value="inv_category_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Inventory Category"
                                    :prepend-inner-icon="icons.mdiChartBox"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="addnewInventoryCategoryModal"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Inventory Category</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout>
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Vendor</div>
                                  <v-autocomplete
                                    v-model="inventory.vendor_id"
                                    :items="vendors"
                                    item-value="vendor_id"
                                    item-text="vendor_name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Vendor"
                                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'addnewvendor' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Inventory Category</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Pricing Matrix</div>
                                  <v-autocomplete
                                    v-model="inventory.pricing_id"
                                    :items="pricings"
                                    item-value="pricing_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Pricing Matrix"
                                    :prepend-inner-icon="icons.mdiMatrix"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'pricingMatrix' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Pricing Matrix</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="8" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Cost</div>
                                  <v-text-field
                                    color="secondary"
                                    v-model="inventory.cost"
                                    label="Cost"
                                    dense
                                    prefix="$"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Quantity</div>
                                  <v-text-field
                                    v-model="inventory.quantity"
                                    color="secondary"
                                    label="Quantity"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Retail Price</div>
                                  <v-text-field
                                    v-model="inventory.retail_price"
                                    color="secondary"
                                    label="Retail Price"
                                    prefix="$"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.markup" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio
                                    v-for="option in options"
                                    :key="option.id"
                                    :label="option.text"
                                    :value="option.value"
                                  ></v-radio>
                                </v-radio-group>
                                <v-col cols="12" class="mt-n5" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.markup"
                                    dense
                                    label="Markup %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.margin" class="mt-n1" row>
                                  <h3 class="mr-5">Margin</h3>
                                  <v-radio
                                    v-for="option in options"
                                    :key="option.id"
                                    :label="option.text"
                                    :value="option.value"
                                  ></v-radio>
                                </v-radio-group>

                                <v-col class="mt-n5" cols="12" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.margin"
                                    dense
                                    label="Margin %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Quantity on hand</div>
                              <v-text-field
                                v-model="inventory.qty_on_hand"
                                color="secondary"
                                label="Quantity on hand"
                                prefix="$"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-switch
                                v-model="inventory.tax"
                                class="mt-n4"
                                flat
                                label="Should this be taxed?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.discounted"
                                class="mt-n3"
                                flat
                                label="Does this customer receive a discount?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_rate"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor rate override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor matrix override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.pricing_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a pricing matrix override?"
                              ></v-switch>
                            </v-col>
                          </v-card>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else-if="inventory.item_type == 'Labor'">
                        <v-toolbar flat>
                          <v-toolbar-title class="page-title">
                            <v-icon class="secondary--text" left light> {{ icons.mdiAccountHardHat }}</v-icon>
                            Labor Inventory
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-btn color="accent" rounded @click.prevent="saveInventoryItems">
                            <v-icon left dark> {{ icons.mdiContentSave }}</v-icon>
                            <span>Save to Inventory</span>
                          </v-btn>
                        </v-toolbar>
                        <v-divider class="mb-2"></v-divider>
                        <v-form class="multi-col-validation">
                          <v-card max-height="350" align="left" class="overflow-y-auto">
                            <v-col md="6" cols="12">
                              <div class="mb-1">Name</div>
                              <input type="hidden" readonly v-model="inventory.item_id" />
                              <input type="hidden" readonly v-model="inventory.item_type" />
                              <input type="hidden" readonly v-model="inventory.key_id" />
                              <v-text-field
                                color="secondary"
                                v-model="inventory.name"
                                label="Name"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Description</div>
                              <v-text-field
                                color="secondary"
                                v-model="inventory.description"
                                label="Description"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Part Number</div>
                              <v-text-field
                                v-model="inventory.part_number"
                                color="secondary"
                                label="Part Number"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Bin Location</div>
                              <v-text-field
                                v-model="inventory.bin_location"
                                color="secondary"
                                label="Bin Location"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Inventory Category</div>
                                  <v-autocomplete
                                    v-model="inventory.inv_category_id"
                                    :items="inventoryCategories"
                                    item-value="inv_category_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Inventory Category"
                                    :prepend-inner-icon="icons.mdiChartBox"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="addnewInventoryCategoryModal"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Inventory Category</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Vendor</div>
                                  <v-autocomplete
                                    v-model="inventory.vendor_id"
                                    :items="vendors"
                                    item-value="vendor_id"
                                    item-text="vendor_name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Vendor"
                                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'addnewvendor' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Vendor</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="8" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="7" cols="12">
                                  <div class="mb-1">Rate</div>
                                  <input type="hidden" name="rate" v-model="inventory.rate" />
                                  <v-autocomplete
                                    v-model="inventory.rate_id"
                                    :items="rates"
                                    item-value="rate_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Rate"
                                    single-line
                                    @change="onRateChange($event)"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="addnewRateModal"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Rate</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col md="5" cols="12">
                                  <div class="mb-1">Hours</div>
                                  <v-text-field
                                    v-model="inventory.hour"
                                    color="secondary"
                                    label="Hours"
                                    dense
                                    outlined
                                    @change="computeHourRate"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group
                                  v-model="inventory.multiplier"
                                  @change="computeHourRate"
                                  class="mt-n1"
                                  row
                                >
                                  <h3 class="mr-5">Hrs. Multiplier</h3>
                                  <v-radio
                                    v-for="option in multipliers"
                                    :key="option.id"
                                    :label="option.text"
                                    :value="option.value"
                                  ></v-radio>
                                </v-radio-group>
                                <v-col cols="12" class="mt-n5" sm="3" md="3">
                                  <v-text-field
                                    :append-icon="icons.mdiClose"
                                    v-model="inventory.multiplier"
                                    dense
                                    label="Multiplier x"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12" sm="12" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="7" cols="12">
                                  <div class="mb-1">Labor Matrix</div>

                                  <v-autocomplete
                                    v-model="inventory.labor_id"
                                    :items="labors"
                                    item-value="labor_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Labor Matrix"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'laborMatrix' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Labor Matrix</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Discount</div>
                              <v-text-field
                                v-model="inventory.discount"
                                color="secondary"
                                label="Discount"
                                prefix="$"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">SubTotal</div>
                              <v-text-field
                                v-model="inventory.subtotal"
                                color="secondary"
                                label="SubTotal"
                                prefix="$"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-switch
                                v-model="inventory.tax"
                                class="mt-n4"
                                flat
                                label="Should this be taxed?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.discounted"
                                class="mt-n3"
                                flat
                                label="Does this customer receive a discount?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_rate"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor rate override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor matrix override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.pricing_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a pricing matrix override?"
                              ></v-switch>
                            </v-col>
                          </v-card>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else-if="inventory.item_type == 'Tire'">
                        <v-toolbar flat>
                          <v-toolbar-title class="page-title">
                            <v-icon class="secondary--text" left light> {{ icons.mdiCarInfo }}</v-icon>
                            Tire Inventory
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-btn color="accent" rounded @click.prevent="saveInventoryItems">
                            <v-icon left dark>{{ icons.mdiContentSave }}</v-icon>
                            <span>Save to Inventory</span>
                          </v-btn>
                        </v-toolbar>
                        <v-divider class="mb-2"></v-divider>
                        <v-form class="multi-col-validation">
                          <v-card max-height="350" align="left" class="overflow-y-auto">
                            <v-col cols="12" md="12">
                              <div class="mb-1">Brand</div>
                              <input type="hidden" readonly v-model="inventory.item_id" />
                              <input type="hidden" readonly v-model="inventory.item_type" />
                              <input type="hidden" readonly v-model="inventory.key_id" />
                              <v-layout row>
                                <v-col cols="12" md="6">
                                  <v-autocomplete
                                    v-model="inventory.brand_id"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Brand"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="addnewBrandModal"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n3 mt-1"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Brand</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col cols="12" md="6">
                              <div class="mb-1">Model</div>
                              <v-text-field
                                color="secondary"
                                v-model="inventory.model"
                                label="Model"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Name</div>
                              <input type="hidden" v-model="inventory.item_type" />
                              <v-text-field
                                color="secondary"
                                v-model="inventory.name"
                                label="Name"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Description</div>

                              <v-text-field
                                color="secondary"
                                v-model="inventory.description"
                                label="Description"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Part Number</div>
                              <v-text-field
                                v-model="inventory.part_number"
                                color="secondary"
                                label="Part Number"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Bin Location</div>
                              <v-text-field
                                v-model="inventory.bin_location"
                                color="secondary"
                                label="Bin Location"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Inventory Category</div>
                                  <v-autocomplete
                                    v-model="inventory.inv_category_id"
                                    :items="inventoryCategories"
                                    item-value="inv_category_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Inventory Category"
                                    :prepend-inner-icon="icons.mdiChartBox"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="addnewInventoryCategoryModal"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Inventory Category</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Vendor</div>
                                  <v-autocomplete
                                    v-model="inventory.vendor_id"
                                    :items="vendors"
                                    item-value="vendor_id"
                                    item-text="vendor_name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Vendor"
                                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'addnewvendor' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Vendor</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12" cols="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.seasonality" class="mt-n1" row>
                                  <h3 class="mr-5">Seasonality</h3>
                                  <v-radio
                                    v-for="option in seasonalities"
                                    :key="option.id"
                                    :label="option.text"
                                    :value="option.value"
                                  ></v-radio>
                                </v-radio-group>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-text-field
                                v-model="inventory.sizes"
                                color="secondary"
                                label="Sizes"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Pricing Matrix</div>
                                  <v-autocomplete
                                    v-model="inventory.pricing_id"
                                    :items="pricings"
                                    item-value="pricing_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Pricing Matrix"
                                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'pricingMatrix' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Pricing Matrix</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="8" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Cost</div>
                                  <v-text-field color="secondary" label="Cost" dense outlined></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Quantity</div>
                                  <v-text-field color="secondary" label="Quantity" dense outlined></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Retail Price</div>
                                  <v-text-field
                                    v-model="inventory.retail_price"
                                    color="secondary"
                                    label="Retail Price"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.markup" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio
                                    v-for="option in options"
                                    :key="option.id"
                                    :label="option.text"
                                    :value="option.value"
                                  ></v-radio>
                                </v-radio-group>
                                <v-col cols="12" class="mt-n5" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.markup"
                                    dense
                                    label="Markup %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.margin" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio
                                    v-for="option in options"
                                    :key="option.id"
                                    :label="option.text"
                                    :value="option.value"
                                  ></v-radio>
                                </v-radio-group>
                                <v-col class="mt-n5" cols="12" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.margin"
                                    dense
                                    label="Margin %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Quantity on hand</div>
                              <v-text-field
                                v-model="inventory.qty_on_hand"
                                color="secondary"
                                label="Quantity on hand"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-switch
                                v-model="inventory.tax"
                                class="mt-n4"
                                flat
                                label="Should this be taxed?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.discounted"
                                class="mt-n3"
                                flat
                                label="Does this customer receive a discount?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_rate"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor rate override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor matrix override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.pricing_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a pricing matrix override?"
                              ></v-switch>
                            </v-col>
                          </v-card>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else-if="inventory.item_type == 'Subcontractor'">
                        <v-toolbar flat>
                          <v-toolbar-title class="page-title">
                            <v-icon class="secondary--text" left light>{{ icons.mdiAccountCog }}</v-icon>
                            Subcontractor Inventory
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-btn color="accent" rounded @click.prevent="">
                            <v-icon left dark> {{ icons.mdiContentSave }}</v-icon>
                            <span>Save to Inventory</span>
                          </v-btn>
                        </v-toolbar>
                        <v-divider class="mb-2"></v-divider>
                        <v-form class="multi-col-validation">
                          <v-card max-height="350" align="left" class="overflow-y-auto">
                            <v-col md="6" cols="12">
                              <div class="mb-1">Name</div>
                              <input type="hidden" readonly v-model="inventory.item_id" />
                              <input type="hidden" readonly v-model="inventory.item_type" />
                              <input type="hidden" readonly v-model="inventory.key_id" />
                              <v-text-field
                                color="secondary"
                                v-model="inventory.name"
                                label="Name"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Description</div>
                              <v-text-field
                                color="secondary"
                                v-model="inventory.description"
                                label="Description"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Part Number</div>
                              <v-text-field
                                v-model="inventory.part_number"
                                color="secondary"
                                label="Part Number"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Bin Location</div>
                              <v-text-field
                                v-model="inventory.bin_location"
                                color="secondary"
                                label="Bin Location"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Inventory Category</div>
                                  <v-autocomplete
                                    v-model="inventory.inv_category_id"
                                    :items="inventoryCategories"
                                    item-value="inv_category_id"
                                    item-text="name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Inventory Category"
                                    :prepend-inner-icon="icons.mdiChartBox"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="addnewInventoryCategoryModal"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Inventory Category</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="12" sm="12" cols="12">
                                  <div class="mb-1">Vendor</div>
                                  <v-autocomplete
                                    v-model="inventory.vendor_id"
                                    :items="vendors"
                                    item-value="vendor_id"
                                    item-text="vendor_name"
                                    outlined
                                    color="secondary"
                                    dense
                                    chips
                                    deletable-chips
                                    small-chips
                                    label="Vendor"
                                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                    single-line
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        @click.stop
                                        @click.prevent="$router.push({ name: 'addnewvendor' })"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-n4 mt-7"
                                        fab
                                        x-small
                                      >
                                        <v-icon size="25">{{ icons.mdiPlus }}</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Add New Vendor</span>
                                  </v-tooltip>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12"> </v-col>
                            <v-col md="8" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Cost</div>
                                  <v-text-field
                                    v-model="inventory.cost"
                                    color="secondary"
                                    label="Cost"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Quantity</div>
                                  <v-text-field
                                    v-model="inventory.quantity"
                                    color="secondary"
                                    label="Quantity"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Retail Price</div>
                                  <v-text-field
                                    v-model="inventory.retail_price"
                                    color="secondary"
                                    label="Retail Price"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.markup" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio label="15%" value="15"></v-radio>
                                  <v-radio label="25%" value="25"></v-radio>
                                  <v-radio label="35%" value="35"></v-radio>
                                  <v-radio label="50%" value="50"></v-radio>
                                </v-radio-group>
                                <v-col cols="12" class="mt-n5" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.markup"
                                    dense
                                    label="Markup %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.margin" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio label="15%" value="15"></v-radio>
                                  <v-radio label="25%" value="25"></v-radio>
                                  <v-radio label="35%" value="35"></v-radio>
                                  <v-radio label="50%" value="50"></v-radio>
                                </v-radio-group>
                                <v-col class="mt-n5" cols="12" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.margin"
                                    dense
                                    label="Margin %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Quantity on hand</div>
                              <v-text-field color="secondary" label="Quantity on hand" dense outlined></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-switch
                                v-model="inventory.tax"
                                class="mt-n4"
                                flat
                                label="Should this be taxed?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.discounted"
                                class="mt-n3"
                                flat
                                label="Does this customer receive a discount?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_rate"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor rate override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor matrix override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.pricing_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a pricing matrix override?"
                              ></v-switch>
                            </v-col>
                          </v-card>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else-if="inventory.item_type == 'Fee'">
                        <v-toolbar flat>
                          <v-toolbar-title class="page-title">
                            <v-icon class="secondary--text" left light>{{ icons.mdiCurrencyUsdOff }}</v-icon>
                            Fee Inventory
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-btn color="accent" rounded @click.prevent="">
                            <v-icon left dark>{{ icons.mdiContentSave }}</v-icon>
                            <span>Save to Inventory</span>
                          </v-btn>
                        </v-toolbar>
                        <v-divider class="mb-2"></v-divider>
                        <v-form class="multi-col-validation">
                          <v-card max-height="350" align="left" class="overflow-y-auto">
                            <v-col md="6" cols="12">
                              <div class="mb-1">Name</div>
                              <input type="hidden" readonly v-model="inventory.item_id" />
                              <input type="hidden" readonly v-model="inventory.item_type" />
                              <input type="hidden" readonly v-model="inventory.key_id" />
                              <v-text-field
                                color="secondary"
                                v-model="inventory.name"
                                label="Name"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Description</div>
                              <v-text-field
                                color="secondary"
                                v-model="inventory.description"
                                label="Description"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Part Number</div>
                              <v-text-field
                                v-model="inventory.part_number"
                                color="secondary"
                                label="Part Number"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Bin Location</div>
                              <v-text-field
                                v-model="inventory.bin_location"
                                color="secondary"
                                label="Bin Location"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Inventory Category</div>
                              <v-autocomplete
                                v-model="inventory.inv_category_id"
                                :items="inventoryCategories"
                                item-value="inv_category_id"
                                item-text="name"
                                outlined
                                color="secondary"
                                dense
                                chips
                                deletable-chips
                                small-chips
                                label="Inventory Category"
                                :prepend-inner-icon="icons.mdiChartBox"
                                single-line
                              ></v-autocomplete>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Vendor</div>
                              <v-autocomplete
                                v-model="inventory.vendor_id"
                                :items="vendors"
                                item-value="vendor_id"
                                item-text="vendor_name"
                                outlined
                                color="secondary"
                                dense
                                chips
                                deletable-chips
                                small-chips
                                label="Vendor"
                                :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                single-line
                              ></v-autocomplete>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Pricing Matrix</div>
                              <v-autocomplete
                                v-model="inventory.pricing_id"
                                :items="pricings"
                                item-value="pricing_id"
                                item-text="name"
                                outlined
                                color="secondary"
                                dense
                                chips
                                deletable-chips
                                small-chips
                                label="Pricing Matrix"
                                :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                                single-line
                              ></v-autocomplete>
                            </v-col>
                            <v-col md="8" cols="12">
                              <v-layout class="ml-n3">
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Cost</div>
                                  <v-text-field
                                    v-model="inventory.cost"
                                    color="secondary"
                                    label="Cost"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Quantity</div>
                                  <v-text-field
                                    v-model="inventory.quantity"
                                    color="secondary"
                                    label="Quantity"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4" cols="12">
                                  <div class="mb-1">Retail Price</div>
                                  <v-text-field
                                    v-model="inventory.retail_price"
                                    color="secondary"
                                    label="Retail Price"
                                    dense
                                    outlined
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.markup" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio label="15%" value="15"></v-radio>
                                  <v-radio label="25%" value="25"></v-radio>
                                  <v-radio label="35%" value="35"></v-radio>
                                  <v-radio label="50%" value="50"></v-radio>
                                </v-radio-group>
                                <v-col cols="12" class="mt-n5" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.markup"
                                    dense
                                    label="Markup %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="12">
                              <v-layout>
                                <v-radio-group v-model="inventory.margin" class="mt-n1" row>
                                  <h3 class="mr-5">Markup</h3>
                                  <v-radio label="15%" value="15"></v-radio>
                                  <v-radio label="25%" value="25"></v-radio>
                                  <v-radio label="35%" value="35"></v-radio>
                                  <v-radio label="50%" value="50"></v-radio>
                                </v-radio-group>
                                <v-col class="mt-n5" cols="12" sm="4" md="4">
                                  <v-text-field
                                    :append-icon="icons.mdiPercentOutline"
                                    v-model="inventory.margin"
                                    dense
                                    label="Margin %"
                                    outlined
                                    color="secondary"
                                  ></v-text-field>
                                </v-col>
                              </v-layout>
                            </v-col>
                            <v-col md="6" cols="12">
                              <div class="mb-1">Quantity on hand</div>
                              <v-text-field
                                v-model="inventory.qty_on_hand"
                                color="secondary"
                                label="Quantity on hand"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-switch
                                v-model="inventory.tax"
                                class="mt-n4"
                                flat
                                label="Should this be taxed?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.discounted"
                                class="mt-n3"
                                flat
                                label="Does this customer receive a discount?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_rate"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor rate override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.labor_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a labor matrix override?"
                              ></v-switch>
                              <v-switch
                                v-model="inventory.pricing_matrix"
                                class="mt-n3"
                                flat
                                label="Does this customer have a pricing matrix override?"
                              ></v-switch>
                            </v-col>
                          </v-card>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <span>Additional Information</span>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-col cols="12" md="12" sm="12" :class="[services.length == 0 ? 'd-none' : 'block']">
          <v-layout>
            <v-btn @click.prevent="showSearchCannedDialog" color="primary" plain>
              <v-icon left>{{ icons.mdiMagnify }}</v-icon>
              <span> Search or browse canned services </span>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
              <v-layout>
                <v-btn @click.prevent="addnewService" color="primary" plain>
                  <v-icon left> {{ icons.mdiPlus }}</v-icon>
                  <span>New Service</span>
                </v-btn>
              </v-layout>
            </div>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
    <addnew-rate
      :dialog.sync="showAddNewRateDialog"
      @open-dialog="showAddNewRateDialog = true"
      @close-dialog="showAddNewRateDialog = false"
    ></addnew-rate>

    <add-new-brand
      :dialog.sync="showAddNewBrandDialog"
      @open-dialog="showAddNewBrandDialog = true"
      @close-dialog="showAddNewBrandDialog = false"
    ></add-new-brand>

    <search-cannedservice
      :dialog.sync="showSearchCannedService"
      :estimate_id="estimate_id"
      @open-dialog="showSearchCannedService = true"
      @close-dialog="showSearchCannedService = false"
    ></search-cannedservice>

    <addnew-inventorycategory
      :dialog.sync="showAddNewInventoryCategory"
      @open-dialog="showAddNewInventoryCategory = true"
      @close-dialog="showAddNewInventoryCategory = false"
    ></addnew-inventorycategory>
  </v-card>
</template>

<script>
import InventoryItem from '@/components/workflow/services/InventoryItem.vue'
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import EditRateDialog from '@/views/settings/rate/EditRate.vue'
import AddNewRateDialog from '@/views/settings/rate/AddNewRate.vue'
import AddNewBrandDialog from '@/views/settings/brand/AddNewBrand.vue'
import SearchCannedService from '@/views/cannedservice/SearchCannedService.vue'
import { mapGetters } from 'vuex'
import AddNewBrand from '@/views/settings/brand/AddNewBrand.vue'
import AddNewInventoryCategory from '@/views/inventorycategory/AddNewInventoryCategory.vue'
import EditInventoryCategory from '@/views/inventorycategory/AddNewInventoryCategory.vue'
import {
  mdiMagnify,
  mdiClose,
  mdiPlus,
  mdiTools,
  mdiCarInfo,
  mdiDotsVertical,
  mdiCheckCircleOutline,
  mdiAlphaRCircleOutline,
  mdiEyeCircleOutline,
  mdiContentSaveCog,
  mdiMenuDown,
  mdiInformationOutline,
  mdiContentSave,
  mdiMinus,
  mdiEyeOffOutline,
  mdiPercentOutline,
  mdiAccountMultipleOutline,
  mdiChartBoxOutline,
  mdiFormatListBulletedSquare,
  mdiAccountHardHat,
  mdiCurrencyUsdOff,
  mdiAccountCog,
  mdiMatrix,
} from '@mdi/js'

export default {
  props: {
    estimate_id: Number,
  },
  name: 'Services',
  components: {
    InventoryItem,
    'edit-rate': EditRateDialog,
    'addnew-rate': AddNewRateDialog,
    'addnew-brand': AddNewBrandDialog,
    AddNewBrand,
    'search-cannedservice': SearchCannedService,
    'addnew-inventorycategory': AddNewInventoryCategory,
    'edit-inventorycategory': EditInventoryCategory,
  },

  created: function () {
    this.getServices()
    this.getInventoryItems()
    this.getInventoryCategories()
    this.getVendors()
    this.getPricings()
    this.getRates()
    this.getLabors()
    this.getBrands()

    window.mitt.on('saveServices', () => {
      this.saveServices()
    })
    window.mitt.on('loadRates', () => {
      this.getRates()
    })
    window.mitt.on('loadInventoryCategories', () => {
      this.getInventoryCategories()
    })
    window.mitt.on('loadServices', () => {
      this.getServices()
    })
  },
  data() {
    return {
      authorized: false,
      current_tab: null,
      showAddNewRateDialog: false,
      showAddNewBrandDialog: false,
      showSearchCannedService: false,
      showAddNewInventoryCategory: false,
      showEditInventoryCategory: false,
      services: [
        {
          service_id: '',
          estimate_id: '',
          name: '',
          notes: '',
          items: [
            {
              item_id: '',
              service_id: '',
              item_type: '',
              name: '',
              description: '',
              retail_price: 0,
              pricing_id: '',
              qty_hrs: 1,
              discount: 0,
              symbol: '',
              status: '',
              subtotal: 0,
              cost: '',
              part_number: '',
              bin_location: '',
              inv_category_id: '',
              vendor_id: '',
              markup: '',
              margin: '',
              hour: '',
              rate_id: '',
              brand_id: '',
              model: '',
              seasonality: '',
              sizes: '',
              rate: '',
              multiplier: '',
              qty_on_hand: '',
              color: '',
              tax: false,
              discounted: false,
              labor_rate: false,
              labor_matrix: false,
              pricing_matrix: false,
            },
          ],
        },
      ],
      inventoryItems: [],
      vendors: [],
      inventoryCategories: [],
      pricings: [],
      rates: [],
      labors: [],
      brands: [],
      laborItems: [],
      inventories: [],
      inventory: {
        inv_id: '',
        item_id: '',
        item_type: '',
        name: '',
        description: '',
        part_number: '',
        retail_price: '',
        quantity: '',
        qty_on_hand: '',
        subtotal: '',
        cost: '',
        bin_location: '',
        inv_category_id: '',
        pricing_id: '',
        labor_id: '',
        vendor_id: '',
        markup: '',
        margin: '',
        rate_id: '',
        hour: 0,
        rate: 0,
        multiplier: '',
        discount: '',
        brand_id: '',
        model: '',
        seasonality: '',
        sizes: '',
        tax: false,
        discounted: false,
        labor_rate: false,
        labor_matrix: false,
        pricing_matrix: false,
      },
      itemtype: [
        {
          item_type: 'Part',
        },
        {
          item_type: 'Labor',
        },
        {
          item_type: 'Tire',
        },
        {
          item_type: 'Subcontractor',
        },
        {
          item_type: 'Fee',
        },
      ],
      itemstatus: [
        {
          status: 'Ordered',
        },
        {
          status: 'Pending',
        },
        {
          status: 'Complete',
        },
        {
          status: 'Waiting for parts',
        },
        {
          status: 'Purchase for parts',
        },
        {
          status: 'Waiting for invoice',
        },
        {
          status: 'Cancelled',
        },
      ],
      multipliers: [
        {
          id: 1,
          text: '1x',
          value: '1.00',
        },
        {
          id: 2,
          text: '1.05x',
          value: '1.05',
        },
        {
          id: 3,
          text: '1.10x',
          value: '1.10',
        },
        {
          id: 4,
          text: '1.15x',
          value: '1.15',
        },
      ],
      options: [
        {
          id: 1,
          text: '15%',
          value: '15.00',
        },
        {
          id: 2,
          text: '25%',
          value: '25.00',
        },
        {
          id: 3,
          text: '35%',
          value: '35.00',
        },
        {
          id: 4,
          text: '50%',
          value: '50.00',
        },
      ],
      seasonalities: [
        {
          id: 1,
          text: 'Summer',
          value: 'Summer',
        },
        {
          id: 2,
          text: 'Winter',
          value: 'Winter',
        },
        {
          id: 3,
          text: 'All Seasons',
          value: 'All',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiDotsVertical,
        mdiCheckCircleOutline,
        mdiAlphaRCircleOutline,
        mdiEyeCircleOutline,
        mdiClose,
        mdiContentSaveCog,
        mdiMenuDown,
        mdiInformationOutline,
        mdiContentSave,
        mdiMinus,
        mdiEyeOffOutline,
        mdiPercentOutline,
        mdiAccountMultipleOutline,
        mdiChartBoxOutline,
        mdiFormatListBulletedSquare,
        mdiTools,
        mdiCarInfo,
        mdiAccountHardHat,
        mdiCurrencyUsdOff,
        mdiAccountCog,
        mdiMatrix,
      },
    }
  },
  methods: {
    getServices: function () {
      this.$store
        .dispatch('service/fetchServices', {
          estimate_id: this.$route.params.id,
        })
        .then(response => {
          this.services = this.fetchServices
        })
        .catch(err => {
          console.log(err)
        })
    },
    getInventoryItems: function () {
      this.$store
        .dispatch('service/fetchInventories')
        .then(response => {
          ;(this.inventoryItems = this.fetchInventory), response
        })
        .catch(err => {
          console.log(err)
        })
    },
    getInventoryCategories: function () {
      this.$store
        .dispatch('inventoryCategory/fetchInventoryCategories')
        .then(response => {
          this.inventoryCategories = this.fetchInventoryCategories
        })
        .catch(err => {
          console.log(err)
        })
    },
    getVendors: function () {
      this.$store
        .dispatch('vendor/fetchVendors')
        .then(response => {
          this.vendors = this.fetchVendors
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPricings: function () {
      this.$store
        .dispatch('pricing/fetchPricings')
        .then(response => {
          this.pricings = this.fetchPricings
        })
        .catch(err => {
          console.log(err)
        })
    },

    getLabors: function () {
      this.$store
        .dispatch('labor/fetchLabors')
        .then(response => {
          this.labors = this.fetchLabors
        })
        .catch(err => {
          console.log(err)
        })
    },
    getRates: function () {
      this.$store
        .dispatch('rate/fetchRates')
        .then(response => {
          this.rates = this.fetchRates
        })
        .catch(err => {
          console.log(err)
        })
    },

    getBrands: function () {
      this.$store
        .dispatch('brand/fetchBrands')
        .then(response => {
          this.brands = this.fetchBrands
        })
        .catch(err => {
          console.log(err)
        })
    },

    computeSubTotal: function (index1, index2) {
      let index = 0
      let subtotal
      this.inventories = this.fetchInventory
      subtotal = this.services[index1].items[index2].retail_price * this.services[index1].items[index2].qty_hrs
      this.services[index1].items[index2].subtotal = subtotal.toFixed(2)
    },
    getLaborItem: function () {
      let multiplier
      this.$store
        .dispatch('inventory/fetchLaborItems', {
          labor_id: this.inventory.labor_id,
          hours: this.inventory.hour,
        })
        .then(response => {
          this.laborItems = this.fetchLaborItems
          this.inventory.multiplier = this.laborItems.multiplier
          this.computeHourRate()
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteItem: function(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`item/deleteItem`, { item_id: id })
              .then(response => {
                this.getServices()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    computeHourRate: function () {
      let SubTotal

      SubTotal = this.inventory.rate * this.inventory.multiplier * this.inventory.hour - this.inventory.discount
      this.inventory.subtotal = SubTotal.toFixed(2)
    },

    onRateChange: function (event) {
      this.getRatesValue(event).then(response => (this.inventory.rate = response))
      this.getHoursValue(event).then(response => (this.inventory.hour = response))
    },
    async getRatesValue(value) {
      let promise = new Promise((resolve, reject) => {
        this.fetchRates.map(item => {
          if (item.rate_id == value) resolve(item.rates)
        })
      })
      return await promise
    },
    async getHoursValue(value) {
      let promise = new Promise((resolve, reject) => {
        this.fetchRates.map(item => {
          if (item.rate_id == value) resolve(item.hours)
        })
      })
      return await promise
    },

    addnewRateModal: function () {
      this.showAddNewRateDialog = true
    },
    addnewBrandModal: function () {
      this.showAddNewBrandDialog = true
    },
    editRateModal: function (rate) {
      this.rate = Object.assign({}, rate)
      this.showEditRateDialog = true
    },
    showSearchCannedDialog: function () {
      this.showSearchCannedService = true
      window.mitt.emit('loadCannedCategories')
    },
    addnewInventoryCategoryModal: function () {
      this.showAddNewInventoryCategory = true
    },
    nextTab(next_tab, id, itemtype, index1, index2) {
      this.current_tab = next_tab
      this.inventory.item_type = itemtype
      this.textfieldName(index1, index2)
    },

    textfieldName: function (index1, index2) {
      this.inventory.item_id = this.services[index1].items[index2].item_id
      this.inventory.item_type = this.services[index1].items[index2].item_type
      this.inventory.name = this.services[index1].items[index2].name
      this.inventory.description = this.services[index1].items[index2].description
      this.inventory.part_number = this.services[index1].items[index2].part_number
      this.inventory.bin_location = this.services[index1].items[index2].bin_location
      this.inventory.inv_category_id = this.services[index1].items[index2].inv_category_id
      this.inventory.vendor_id = this.services[index1].items[index2].vendor_id
      this.inventory.retail_price = this.services[index1].items[index2].retail_price
      this.inventory.pricing_id = this.services[index1].items[index2].pricing_id
      this.inventory.quantity = this.services[index1].items[index2].qty_hrs
      this.inventory.qty_on_hand = this.services[index1].items[index2].qty_on_hand
      this.inventory.markup = this.services[index1].items[index2].markup
      this.inventory.margin = this.services[index1].items[index2].margin
      this.inventory.brand_id = this.services[index1].items[index2].brand_id
      this.inventory.seasonality = this.services[index1].items[index2].seasonality
      this.inventory.sizes = this.services[index1].items[index2].sizes
      this.inventory.model = this.services[index1].items[index2].model
      this.inventory.subtotal = this.services[index1].items[index2].subtotal
      this.inventory.tax = this.services[index1].items[index2].tax
      this.inventory.discounted = this.services[index1].items[index2].discounted
      this.inventory.labor_rate = this.services[index1].items[index2].labor_rate
      this.inventory.labor_matrix = this.services[index1].items[index2].labor_matrix
      this.inventory.pricing_matrix = this.services[index1].items[index2].pricing_matrix
    },

    saveServices: function () {
      this.$store
        .dispatch(`service/storeNewService`, {
          services: this.services,
        })
        .then(response => {
          this.updateServiceItems()
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record saved successfully',
          })

          this.getServices()
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateServiceItems: function () {
      this.$store
        .dispatch(`service/updateNewServiceItems`, {
          item_id: this.inventory.item_id,
          data: this.inventory,
        })
        .then(response => {})
        .catch(err => {
          console.log(err)
        })
    },

    saveInventoryItems: function () {
      this.$store
        .dispatch(`inventory/storeNewInventoryItem`, this.inventory)
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record saved successfully',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    addnewService: function () {
      this.services.push({
        service_id: '',
        service_key: '',
        name: '',
        notes: '',
        estimate_id: this.estimate_id ? this.estimate_id : '',
        items: [],
      })
    },

    removeService: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`service/deleteService`, {
                service_id: id,
              })
              .then(response => {
                this.getServices()
              })
          }
        })
    },

    addnewItem: function (service) {
      service.push({
        item_id: '',
        key_id: '',
        service_key: '',
        service_id: '',
        item_type: '',
        name: '',
        description: '',
        retail_price: '',
        pricing_id: '',
        labor_id: '',
        qty_hrs: 1,
        discount: 1,
        symbol: '',
        status: '',
        subtotal: '',
        cost: '',
        part_number: '',
        bin_location: '',
        inv_category_id: '',
        vendor_id: '',
        qty_on_hand: '',
        markup: '',
        margin: '',
        hour: '',
        brand_id: '',
        model: '',
        seasonality: '',
        sizes: '',
        color: '',
        rate_id: '',
        notes: '',
        rate: '',
        multiplier: '',
        color: '',
      })
    },
  },

  computed: {
    ...mapGetters({
      fetchServices: 'service/fetchServices',
      fetchInventory: 'service/fetchInventory',
      fetchVendors: 'vendor/fetchVendors',
      fetchInventoryCategories: 'inventoryCategory/fetchInventoryCategories',
      fetchPricings: 'pricing/fetchPricings',
      fetchLabors: 'labor/fetchLabors',
      fetchRates: 'rate/fetchRates',
      fetchLaborItems: 'inventory/fetchLaborItems',
      fetchTags: 'tag/fetchTags',
      getItemsById: 'item/getItemsById',
      fetchBrands: 'brand/fetchBrands',
    }),
  },

  watch: {
    onComputeRate() {
      this.computeHourRate()
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  order: 0;
}
.header {
  order: 1;
}
